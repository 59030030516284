import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import { Footer, Header, Menu, Search, SEO } from '../components';

export default function SearchPage() {
  const blogPosts = useStaticQuery(graphql`
    query SearchPostsQuery {
      allWpPost {
        edges {
          node {
            author {
              node {
                name
                avatar {
                  url
                }
                databaseId
              }
            }
            categories {
              nodes {
                name
                slug
                databaseId
              }
            }
            date(formatString: "D [de] MMMM [de] YYYY", locale: "pt-br")
            excerpt
            featuredImage {
              node {
                altText
                localFile {
                  childImageSharp {
                    fluid(maxHeight: 310, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                      src
                    }
                  }
                }
                title
              }
            }
            databaseId
            slug
            title
          }
        }
      }
    }
  `);

  return (
    <>
      <SEO title="Busca" />

      <Header />

      <Menu />

      <Search posts={blogPosts.allWpPost.edges} />

      <Footer />
    </>
  );
}
